@import "minima";

@font-face {
  font-family: "Computer Modern";
  src: url("/assets/fonts/cmunrm.woff2") format("woff2"),
    url("/assets/fonts/cmunrm.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: "Computer Modern";
  src: url("/assets/fonts/cmunti.woff2") format("woff2"),
    url("/assets/fonts/cmunti.otf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: fallback;
}
@font-face {
  font-family: "Computer Modern";
  src: url("/assets/fonts/cmunbi.woff2") format("woff2"),
    url("/assets/fonts/cmunbi.otf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: fallback;
}
@font-face {
  font-family: "Computer Modern";
  src: url("/assets/fonts/cmunbx.woff2") format("woff2"),
    url("/assets/fonts/cmunbx.otf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: "Computer Typewriter";
  src: url("/assets/fonts/cmuntt.woff2") format("woff2"),
    url("/assets/fonts/cmuntt.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: "Computer Typewriter";
  src: url("/assets/fonts/cmunit.woff2") format("woff2"),
    url("/assets/fonts/cmunit.otf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: fallback;
}
@font-face {
  font-family: "Computer Typewriter";
  src: url("/assets/fonts/cmuntb.woff2") format("woff2"),
    url("/assets/fonts/cmuntb.otf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: "Computer Typewriter";
  src: url("/assets/fonts/cmuntx.woff2") format("woff2"),
    url("/assets/fonts/cmuntx.otf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: fallback;
}

a {
  color: #0000ee;
  text-decoration: underline;
  text-decoration-thickness: 0.1px;
}
a:visited {
  color: #551a8b;
}

ul.social-media-list {
  float: right;
  li {
    a {
      text-decoration: none;
      svg.svg-icon {
        padding: 0;
      }
      span.username {
        text-decoration: underline;
        text-decoration-thickness: 0.1px;
      }
    }
  }
}

.footer-col-wrapper {
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-template-rows: auto;
}
.footer-col-1 {
  width: auto;
}
.footer-col-2 {
  width: auto;
}
